<template>
    <v-container class="list-bg min-h-full">
        <VTopToolbar title="Jobs">
            <!-- Override right nav button -->
            <template slot="right">
                <v-icon @click="$router.push({ name: 'bookmarks' })">
                    mdi-star-box-outline
                </v-icon>
                <v-icon v-visible="!loading" @click="refresh()">
                    mdi-cached
                </v-icon>
            </template>
        </VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader
                type="image, text, image, text, image"
            ></v-skeleton-loader>
        </div>

        <h4
            class="grey-text font-weight-light mt-3 mb-5"
            v-if="!loading && !sharedos.length"
        >
            There are no jobs.
        </h4>

        <v-card
            class="mx-auto mb-3"
            outlined
            v-show="!loading"
            v-for="sharedo in sharedos"
            :key="sharedo.id"
        >
            <div class="d-flex" @click.stop="navTo(sharedo)">
                <div class="flex-grow-1">
                    <v-card-title class="align-start flex-nowrap pb-2">
                        <div class="flex-grow-0">
                            <v-icon left>{{ sharedo.icon }}</v-icon>
                        </div>
                        <div class="flex-grow-1" style="word-break: break-word">
                            <span
                                class="
                                    font-weight-bold
                                    grey--text
                                    text--darken-3
                                "
                                >{{ sharedo.title }}</span
                            >
                        </div>
                    </v-card-title>

                    <v-card-text class="grey--text text--darken-1">
                        <div class="text-truncate">
                            {{ sharedo.typeErsOrDamages }} &ndash;
                            {{ sharedo.phase }} &ndash;
                            {{ sharedo.reference || "No reference" }}
                        </div>
                        <div class="text-truncate">
                            <v-icon left small style="vertical-align: inherit"
                                >mdi-account-hard-hat</v-icon
                            >
                            <span>{{
                                sharedo.vmEngineer || "No engineer"
                            }}</span>
                        </div>

                        <div class="pt-2">
                            <v-row no-gutters>
                                <v-col cols="4" class="font-weight-bold"
                                    >Instruction date</v-col
                                >
                                <v-col cols="8">
                                    {{
                                        $moment(sharedo.instructionDate).format(
                                            "DD/MM/YYYY HH:mm"
                                        )
                                    }}
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" class="font-weight-bold"
                                    >Location</v-col
                                >
                                <v-col cols="8">{{
                                    sharedo.incidentLocation
                                }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="4" class="font-weight-bold"
                                    >Remedy no.</v-col
                                >
                                <v-col cols="8">{{
                                    sharedo.remedyNumber
                                }}</v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </div>

                <div class="flex-grow-0 d-flex">
                    <v-icon large color="grey lighten-2"
                        >mdi-chevron-right</v-icon
                    >
                </div>
            </div>
            <v-divider></v-divider>

            <v-card-actions class="my-1">
                <v-datetime-traffic-light :value="sharedo.createdDate" />
                <v-spacer></v-spacer>
                <v-icon small color="grey" v-if="sharedo.anyDocs" class="mr-2"
                    >mdi-paperclip</v-icon
                >
            </v-card-actions>
        </v-card>

        <v-btn
            outlined
            rounded
            block
            color="primary"
            v-show="!loading && hasMore"
            :loading="loadingMore"
            @click.stop="loadNextPage()"
        >
            More
        </v-btn>
    </v-container>
</template>

  
<script>
import instructionsAgent from "./instructionsAgent";
import { InstallPrompt, SharedoProfile } from "@sharedo/mobile-core";

const rpp = 10;

export default {
    data() {
        return {
            loading: true,
            loadingMore: false,
            sharedos: [],
            lastPageLoaded: 0,
            hasMore: true,
            persona: null,
        };
    },
    mounted() {
        InstallPrompt.init();

        this.persona = SharedoProfile.profile.persona;

        this.loadPage()
            .then((result) => {
                this.hasMore = result.hasMore;
            })
            .catch(console.error);
    },
    methods: {
        loadPage(refresh) {
            return new Promise((resolve, reject) => {
                this.lastPageLoaded++;

                instructionsAgent
                    .getMyInstructions(rpp, this.lastPageLoaded)
                    .then((data) => {
                        this.loading = false;

                        var models = data.results.map((t) => ({
                            id: t.id,
                            reference: t.data.reference,
                            title: t.data.title,
                            instructionDate: new Date(
                                t.data[
                                    "keyDates.kd-instruction-date.taskDueDate.date.local.value"
                                ]
                            ),
                            icon: t.data["type.iconClass"],
                            type: t.data["type.name"],
                            typeErsOrDamages: t.data["type.name"]
                                .split(" ")
                                .pop(),
                            phase: t.data["phase.name"],
                            vmEngineer: t.data["roles.vm-engineer.ods.name"],
                            anyDocs: !!t.data["documents!1.title"],
                            link: "/instructions/" + t.id,
                            incidentLocation:
                                t.data[
                                    "incidentDetailsLocation.location.formatted"
                                ],
                            remedyNumber:
                                t.data[
                                    "form-b269621a-e7eb-4e97-bac9-237b99af0f04-RemedyDetails.vm-reference-text"
                                ],
                            createdDate: t.data["createdDate.utc.value"],
                        }));

                        models.forEach((m) => {
                            this.sharedos.push(m);
                        });

                        var totalPages = Math.ceil(data.totalCount / rpp);
                        var hasMore = totalPages > this.lastPageLoaded;

                        resolve({ hasMore: hasMore });
                    })
                    .catch(console.error);
            });
        },

        refresh() {
            this.lastPageLoaded = 0;
            this.loading = true;

            // This should also cause a scroll to top
            this.sharedos.splice(0, this.sharedos.length);

            this.loadPage(true)
                .then((result) => {
                    this.hasMore = result.hasMore;
                })
                .catch(console.error);
        },

        loadNextPage() {
            this.loadingMore = true;
            this.loadPage()
                .then((result) => {
                    this.loadingMore = false;
                    if (!result.hasMore) {
                        this.hasMore = false;
                    }
                })
                .catch(console.error);
        },

        navTo(sharedo) {
            this.$router.push({ path: sharedo.link });
        },
    },
};
</script>

<style scoped>
.v-card__title {
    font-size: 1.1rem;
    line-height: 1.5rem;
}

.v-card__title .v-icon.v-icon {
    font-size: 22px;
}

.v-card__text,
.card-text-small {
    font-size: 0.775rem;
}

/* Purposefully unspecific CSS so red--text overrides us */
.task-lag {
    color: var(--v-secondary-darken1);
}
</style>